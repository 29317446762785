@import '../../styles/variables.scss';

.next-retailer .concept-view {
  h2 {
    font-size: $font-size-xl;
    font-family: $font-default;
  }

  .no-delivery-available {
    margin-bottom: 20px;
  }

  .concept-header {
    h2 {
      font-size: $font-size-xxl;
      font-family: $font-default-bold;
      width: 100%;
    }
    display: flex;
    .concept-meta {
      flex: 1 1 400px;
      font-size: $font-size-d;
      ul {
        list-style: none;
        padding-left: 0;
      }
    }
    .concept-actions {
      flex: 0.2 1 200px;
      text-align: right;
    }

    .concept-participations {
      flex: 0.2 1 200px;
      text-align: right;
      align-self: flex-end;
      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  .concept-options {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .concept-options-label {
      margin-bottom: 5px;
    }
  }

  .concept-box {
    .concept-box-content {
      display: flex;
      .info {
        flex: 1 1 400px;
      }
    }
  }

  .template-list {
    display: flex;
    flex-direction: column;
    .batch-participate {
      align-self: center;
      max-width: 350px;
    }
  }

  .print-notice {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: $margin-default;
    }
  }
}

p {
  padding-top: 2px;
  align-self: center;
}
